import React, { FC, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import OrbContainer from './OrbContainer';

import useFormFactors from '../helpers/useFormFactors';

import blob from '../images/blobs/blob.png';

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.lightPeach2};
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
`;

const TopBlob = styled.img`
  height: 800px;
  left: -140px;
  position: absolute;
  top: 56px;
  width: 800px;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    height: 560px;
    left: -48px;
    position: absolute;
    top: 124px;
    width: 560px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    height: auto;
    left: -160px;
    position: absolute;
    top: 0px;
    width: auto;
  }
`;

const MiddleBlob = styled.img`
  display:none;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    height: 560px;
    right: -160px;
    position: absolute;
    top: 32%;
    width: auto;
    display:inherit;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    height: 742px;
    right: -12%;
    position: absolute;
    top: 32%;
    width: auto;
    display: inherit;
  }
`;

const BottomBlob = styled.img`
  height: 320px;
  bottom: 4%;
  left: -12%;
  position: absolute;
  width: auto;
  display:inherit;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    display:none;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    display:none;
  }
`;

interface Props {
  showBottomBlob?: boolean;
  showMiddleBlob?: boolean;
  showTopBlob?: boolean;
}
const Background: FC<Props> = ({ showBottomBlob=true, showMiddleBlob=true, showTopBlob=true })=> {
  const [topBlobElement, setTopBlobElement] = useState<HTMLImageElement>();
  const [middleBlobElement, setMiddleBlobElement] = useState<HTMLImageElement>();
  const [bottomBlobElement, setBottomBlobElement] = useState<HTMLImageElement>();

  const formFactors = useFormFactors();

  const topBlobRef = useCallback((element: HTMLImageElement)=> {
    setTopBlobElement(element);
  }, []);

  const middleBlobRef = useCallback((element: HTMLImageElement)=> {
    setMiddleBlobElement(element);
  }, []);

  const bottomBlobRef = useCallback((element: HTMLImageElement)=> {
    setBottomBlobElement(element);
  }, []);

  const orbMovementMultiplier = useMemo(()=> (
    formFactors.mobile ?
      10 : formFactors.tablet ?
        15 : 20
  ), []);

  return (
    <Container>
      {showTopBlob ?
        <OrbContainer childElement={topBlobElement} multiplier={orbMovementMultiplier}>
          <TopBlob alt='' src={blob} ref={topBlobRef} />
        </OrbContainer> :
        null
      }
      {showMiddleBlob ?
        <OrbContainer childElement={middleBlobElement} multiplier={orbMovementMultiplier}>
          <MiddleBlob alt='' src={blob} ref={middleBlobRef} />
        </OrbContainer> :
        null
      }
      {showBottomBlob ?
        <OrbContainer childElement={bottomBlobElement} multiplier={orbMovementMultiplier}>
          <BottomBlob alt='' src={blob} ref={bottomBlobRef} />
        </OrbContainer> :
        null
      }
    </Container>
  );
}

export default Background;