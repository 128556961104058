import React, { FC } from 'react';
import styled from 'styled-components';


const Layout = styled.section`
  display: flex;
  z-index: 2;
  flex-direction: column;
  padding: 102px 16px;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    padding: 275px 64px 175px 64px ;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    padding: 275px 64px 175px 64px ;
  }
`;


const Heading = styled.p`
  color: ${(props) => props.theme.colors.blackText};
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  margin: 0 0 16px 0;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 44px;
    line-height: 55px;
    margin: 0 auto;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 64px;
    line-height: 70px;
  }
`;


const SubTitle = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 0 0 62px;
  text-align: center;
  z-index: 1;
  max-width: 222px;
  align-self: center;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 18px;
    line-height: 24px;
    max-width: initial;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 28px;
    font-weight: 400;
    line-height: 34px;
    padding: 8px 0 0 0;
    max-width: initial;
  }
`;




const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1124px;


  @media ${(props) => props.theme.breakpoints.tabletP} {
    flex-direction: row;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 0 24px;
  position: relative;
  width: 100%;
  margin: 0 0 59px 0;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    width: 50%;
    margin: 0;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    width: 25%;
    margin: 0;
  }
`;

const StyledLink = styled.a`
  text-align: center;
  text-decoration: underline;
  line-height: 24px;
  font-size: 18px;
  white-space: nowrap;


  @media ${(props) => props.theme.breakpoints.tabletP} {
    text-align: left;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    text-align: left;
  }
`;


interface Props {
  linkAddress: string;
}

const OccasionList: FC = () => {

    return (
        <Layout>
            <Heading>
                Use illume for <span style={{color: '#FEB81C'}}><em>any</em></span> occasion
            </Heading>
            <SubTitle>
                Celebrating someone special has never been easier.
            </SubTitle>
            <Body>
                <Section>
                  <StyledLink href={'/occasions/birthday/friend'}>Birthday for a Friend</StyledLink>
                  <StyledLink href={'/occasions/birthday/colleague'}>Birthday for a Colleague</StyledLink>
                  <StyledLink href={'/occasions/birthday/mom'}>Birthday for Mom</StyledLink>
                  <StyledLink href={'/occasions/birthday/dad'}>Birthday for Dad</StyledLink>
                  <StyledLink href={'/occasions/birthday/brother'}>Birthday for Brother</StyledLink>
                </Section>
                <Section>
                  <StyledLink href={'/occasions/birthday/sister'}>Birthday for Sister</StyledLink>
                  <StyledLink href={'/occasions/birthday/best-friend'}>Birthday for Best Friend</StyledLink>
                  <StyledLink href={'/occasions/birthday/boyfriend'}>Birthday for Boyfriend</StyledLink>
                  <StyledLink href={'/occasions/birthday/girlfriend'}>Birthday for Girlfriend</StyledLink>
                  <StyledLink href={'/occasions/birthday/relative'}>Birthday for Relative</StyledLink>
                </Section>
                <Section>
                  <StyledLink href={'/occasions/mothers-day'}>Mother's Day</StyledLink>
                  <StyledLink href={'/occasions/fathers-day'}>Father's Day</StyledLink>
                  <StyledLink href={'/occasions/anniversary'}>Work Anniversary</StyledLink>
                  <StyledLink href={'/occasions/retirement'}>Retirement</StyledLink>
                  <StyledLink href={'/occasions/teachers'}>Teacher Appreciation</StyledLink>
                </Section>
                <Section>
                  <StyledLink href={'/occasions/thank-you'}>Thank You</StyledLink>
                  <StyledLink href={'/occasions/farewell'}>Farewell</StyledLink>
                  <StyledLink href={'/occasions/baby-shower'}>Baby Shower</StyledLink>
                  <StyledLink href={'/occasions/the-graduate'}>Graduation</StyledLink>
                  <StyledLink href={'/occasions/get-well-soon'}>Get Well Soon</StyledLink>
                </Section>
            </Body>
        </Layout>
    );
}
  
export default OccasionList;