import React, { FC } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { motion } from 'framer-motion';
import MetaTags from '../components/MetaTags';
import { theme } from '../styles/theme';
import Background from '../components/BackgroundTopCenterBlob';
import Header from '../components/Header2';
import MeaningfulGroupGifts from '../sections/occasion/MeaningfulGroupGifts';
import Join from '../sections/Join';
import FooterNew from '../sections/common/FooterWithoutFormFactorHook';
import OccasionList from '../sections/occasion/OccasionList';

const Layout = styled(motion.div)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0 24px;
  position: relative;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.tabletP} {
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    -webkit-backface-visibility: hidden;
  }
`;

const Occasion: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <MetaTags
        title='Occasions | illume'
        description={
          'From birthdays to just because, illume is reimagining how the world celebrates.'
        }
      />
      <Layout>
        <Background showTopBlob={false} />
        <Header />
        <MeaningfulGroupGifts />
        <OccasionList />
        <Join />
        <FooterNew/>
      </Layout>
    </ThemeProvider>
  );
};

export default Occasion;
