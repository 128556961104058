import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { theme } from '../../styles/theme';
import LinkButton from '../../components/LinkButton';
import OrbContainer from '../../components/OrbContainer';
import useFormFactors from '../../helpers/useFormFactors';
import useMeaningfulGroupGifts from '../common/hooks/useMeaningfullGroupGifts';
import { CREATE_CARD_LINK, EXPLORE_GIFTS } from '../../constants/links';
import blob from '../../images/blobs/blob.png';
import ReviewsSlider from '../../components/reviewSlider/ReviewSlider';

const Layout = styled.section`
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    flex-direction: row;
    padding: 32px 0;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    flex-direction: row;
    padding: 40px 0;
  }
`;

const Section = styled.div`
  position: relative;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    width: 50%;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    width: 60%;
  }
`;

const Body = styled.div`
  padding: 24px 14px;
  width: 100%;
  z-index: 2;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    padding: 0 0 0 54px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    padding: 48px 0 0 86px;
  }
`;

const Heading = styled.p`
  color: ${(props) => props.theme.colors.blackText};
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  margin: 0 0 16px 0;
  text-align: left;
  max-width: 462px;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 44px;
    line-height: 55px;
    margin: 0 auto 0 0;
    max-width: 370px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 64px;
    line-height: 70px;
    max-width: 462px;
  }
`;

const Paragraph = styled.p`
  color: ${(props) => props.theme.colors.darkGray};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin: 0 0 16px 0;
  text-align: left;
  max-width: 462px;

  em strong {
    font-weight: 500;
  }

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 16px;
    line-height: 22px;
    margin: 16px 0 0 0;
    width: 80%;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 18px;
    line-height: 26px;
    margin: 0;
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    flex-direction: row;
    justify-content: flex-start;
    max-width: 328px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    flex-direction: row;
    justify-content: flex-start;
    max-width: 456px;
  }
`;

const GroupGiftLink = styled(LinkButton)`
  margin: 0 0 8px 0;
  width: 100%;
  font-weight: normal;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    margin: 24px 15px 0 0;
    max-width: 184px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    margin: 24px 15px 0 0;
    max-width: 240px;
  }
`;

const ExploreGiftsLink = styled(LinkButton)`
  background: ${(props) => props.theme.colors.lightPeach};
  color: #000;
  width: 100%;
  font-weight: normal;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    margin: 24px 0 0 0;
    max-width: 136px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    margin: 24px 0 0 0;
    max-width: 176px;
  }
`;

const Ticker = styled.div`
  color: ${(props) => props.theme.colors.darkGray};
  display: flex;
  font-size: 14px;
  justify-content: center;
  line-height: 18px;
  margin: 8px 0 0 0;

  strong {
    padding: 0 4px 0 0;
  }

  @media ${(props) => props.theme.breakpoints.tabletP} {
    max-width: 184px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    max-width: 240px;
  }
`;

const Blob = styled.img`
  height: 800px;
  left: -219px;
  position: absolute;
  top: -220px;
  width: 800px;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    height: 600px;
    left: -86px;
    position: absolute;
    top: -62px;
    width: 600px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    height: 800px;
    left: -104px;
    position: absolute;
    top: -132px;
    width: 800px;
  }
`;

/**
 *
 * @deprecated
 * duplicate
 */
const MeaningfulGroupGifts: FC = () => {
  const [blobElement, setBlobElement] = useState<HTMLImageElement>();
  const notesRef = useRef<HTMLElement>(null);

  const formFactors = useFormFactors();

  const { numberOfNotes } = useMeaningfulGroupGifts({ notesRef });

  const blobRef = useCallback((element: HTMLImageElement) => {
    setBlobElement(element);
  }, []);

  const orbMovementMultiplier = useMemo(
    () => (formFactors.mobile ? 10 : formFactors.tablet ? 15 : 20),
    []
  );

  return (
    <Layout>
      <OrbContainer
        childElement={blobElement}
        multiplier={orbMovementMultiplier}
        style={{ zIndex: 1 }}
      >
        <Blob alt='' src={blob} ref={blobRef} />
      </OrbContainer>
      <Section style={{ zIndex: 3 }}>
        <Body>
          <Heading>
            Meaningful, and unique group gifts for any{' '}
            <span style={{ color: '#FEB81C' }}>occasion</span>
          </Heading>
          <Paragraph>
            Send a group gift with personalized messages to celebrate an individual for an occasion
            or just because,{' '}
            <em>
              <strong>no address necessary.</strong>
            </em>
          </Paragraph>
          <Actions>
            <GroupGiftLink href={CREATE_CARD_LINK}>Create your group gift</GroupGiftLink>
            <ExploreGiftsLink href={EXPLORE_GIFTS}>Explore all gifts</ExploreGiftsLink>
          </Actions>
          <Ticker>
            <strong>{numberOfNotes}</strong> notes sent with love &#10084;&#65039;
          </Ticker>
        </Body>
      </Section>
      <Section style={{ zIndex: 0, paddingTop: 24 }}>
        <ReviewsSlider showCta={false} showReviews={!formFactors.mobile} />
      </Section>
    </Layout>
  );
};

export default MeaningfulGroupGifts;
